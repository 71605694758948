import { Layout, Tabs } from 'antd'

import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import 'moment/locale/vi'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { getPage, tabConfigPrice } from '../../helpers/constants'
import ContentFee from './Content'
import { useEffect, useState } from 'react'
const { Content } = Layout
const TabPane = Tabs.TabPane

const SkuManagement = observer((props: any) => {
   const { t } = useTranslation()
   const SkuStore = useStore('SkuStore')
   const UserStore = useStore('UserStore')
   const WarehouseStore = useStore('WarehouseStore')
   const { rows } = WarehouseStore.warehouseList
   const onChangeTab = (key: string) => {
      SkuStore.setActiveTab(key)
   }

   useEffect(() => {
      getListData(0)
   }, [])
   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param }

         const res = await WarehouseStore.getWarehouseList(params)
         SkuStore.setActiveTab(res?.rows[0].id)
      } catch (error) {
         //  console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }
   return (
      <Content {...props}>
         <Tabs defaultActiveKey={SkuStore.activeTab}  destroyInactiveTabPane={true} onChange={onChangeTab}>
            {rows.map((store: any) => {
               return (
                  <TabPane tab={store.name} key={store.id}>
                     <ContentFee {...props} idTab={store.id} key={store.id} />
                  </TabPane>
               )
            })}
            {/* <TabPane tab={'Vu tran'} key={'1'}>
               <ContentFee {...props} idTab={'Vu tran'} key={'1'} />
            </TabPane> */}
            {/* <TabPane tab={t(GLOBAL_CLIENT.mFulfillment)} key={tabConfigPrice.fulfillment}>
               <ContentFee
                  {...props}
                  idTab={tabConfigPrice.fulfillment}
                  key={tabConfigPrice.fulfillment}
                  listFilter={[{ id: '', name: 'All' }, ...UserStore.listFulfillment.rows]}
               />
            </TabPane> */}
         </Tabs>
      </Content>
   )
})

export default SkuManagement
