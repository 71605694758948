import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import i18n from '../../helpers/i18n'

export const adminMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.mAccount),
      path: '/m-account'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mTag),
      path: '/m-tags'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.products),
      path: '/m-products'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.order),
      path: '/m-order'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.warehouse),
      path: '/m-warehouse'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mFund),
      path: '/m-fund'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mPrice),
      path: '/m-price'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.mConfig),
      path: '/m-config'
   },
   {
      label: 'Báo cáo',
      path: '/m-report'
   },
   {
      label: 'Cấu hình SKU',
      path: '/m-sku'
   }
]
export const sellerMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.products),
      path: '/list-products'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.order),
      path: '/m-order'
   }
]
export const supplierMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]
export const fulfillmentMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.products),
      path: '/m-products'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.order),
      path: '/m-order'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.warehouse),
      path: '/m-warehouse'
   }
]
export const managerOrderMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.products),
      path: '/m-products'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.order),
      path: '/m-order'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.warehouse),
      path: '/m-warehouse'
   }
]
export const managerStockMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.mTag),
      path: '/m-tags'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.products),
      path: '/m-products'
   },

   {
      label: i18n.t(GLOBAL_CLIENT.order),
      path: '/m-order'
   },
   {
      label: i18n.t(GLOBAL_CLIENT.warehouse),
      path: '/m-warehouse'
   }
]

export const accountantMeta = [
   {
      label: i18n.t(GLOBAL_CLIENT.mFund),
      path: '/m-fund'
   }
]

export const supporterMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]

export const userMeta = [
   {
      label: 'Trang chủ',
      path: '/home'
   }
]

export const ROUTER_PATH = {
   // Admin
   ConfigManagementScreen: 'm-config',
   SellerManagementScreen: 'm-seller',
   FulfillmentManagementScreen: 'm-fulfillment',
   TagManagementScreen: 'm-tags',
   SupplierManagement: 'm-supplier',
   PriceManagement: 'm-price',
   EmployeeManagementScreen: 'm-employee',
   AccountManagementScreen: 'm-account',
   CreateEmployeeScreen: 'm-employee/create',
   ProductsManagement: 'm-products',
   ReportManagement: 'm-report',
   OrderManagement: 'm-order',
   WarehouseManagement: 'm-warehouse',
   FundManagement: 'm-fund',
   ListProductsManagement: 'list-products',
   IntroPage: 'home',
   SkuManagementScreen: 'm-sku',

   ProfileScreen: 'profiles',
   ResetPassword: 'reset-password'
}
