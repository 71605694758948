import React, { useContext, useRef } from 'react'
import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, Button, Typography, Table, Popconfirm } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import type { FormInstance } from 'antd/es/form'
import { debounce, size } from 'lodash'
import { formatCurrency, showMessageError } from 'src/helpers/functions'
import { getStatusOrder, DATE_TIME_FORMAT_CLIENT_short } from 'src/helpers/constants'
import moment from 'moment'
import { Loading } from 'src/common'

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}
const EditableContext = React.createContext<FormInstance<any> | null>(null)

interface Item {
   key: string
   sku: any
   name: string
   attribute: string
   quantity: any
}

interface EditableRowProps {
   index: number
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
   const [form] = Form.useForm()
   return (
      <Form form={form} component={false}>
         <EditableContext.Provider value={form}>
            <tr {...props} />
         </EditableContext.Provider>
      </Form>
   )
}

interface EditableCellProps {
   title: React.ReactNode
   editable: boolean
   children: React.ReactNode
   dataIndex: keyof Item
   record: Item
   handleSave: (record: Item) => void
}

const EditableCell: React.FC<EditableCellProps> = ({
   title,
   editable,
   children,
   dataIndex,
   record,
   handleSave,
   ...restProps
}) => {
   const [editing, setEditing] = useState(false)
   const inputRef = useRef<any>(null)
   const form = useContext(EditableContext)!

   useEffect(() => {
      if (editing) {
         inputRef.current!.focus()
      }
   }, [editing])

   const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
   }

   const save = async () => {
      try {
         const values = await form.validateFields()

         toggleEdit()
         handleSave({ ...record, ...values })
      } catch (errInfo) {
         console.log('Save failed:', errInfo)
      }
   }

   let childNode = children

   if (editable) {
      childNode = editing ? (
         <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
               {
                  required: true,
                  message: `${title} is required.`
               }
            ]}
         >
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
         </Form.Item>
      ) : (
         <div className="editable-cell-value-wrap" style={{ paddingRight: 24, height: '26px' }} onClick={toggleEdit}>
            {children}
         </div>
      )
   }

   return <td {...restProps}>{childNode}</td>
}

type EditableTableProps = Parameters<typeof Table>[0]

interface DataType {
   key: React.Key
   sku: any
   name: string
   attribute: string
   quantity: any
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export const ModalWrapper = styled.div`
   .ant-table::-webkit-scrollbar {
      width: 8px;
      /* width of the entire scrollbar */
   }

   // .ant-table-content::-webkit-scrollbar-track {
   //    background: orange;
   //    /* color of the tracking area */
   // }

   // .ant-table-content::-webkit-scrollbar-thumb {
   //    background-color: blue;
   //    /* color of the scroll thumb */
   //    border-radius: 20px;
   //    /* roundness of the scroll thumb */
   //    border: 3px solid orange;
   //    /* creates padding around scroll thumb */
   // }
   .editable-cell {
      position: relative;
   }

   .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
   }

   .editable-row:hover .editable-cell-value-wrap {
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
   }

   [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
      border: 1px solid #434343;
   }
`
const AssignWarehouse = observer((props: any) => {
   const { t } = useTranslation()

   const { item: data, visible, onOk, onCancel, onlyView } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars

   const OrdersStore = useStore('OrdersStore')
   const AuthStore = useStore('AuthStore')
   const role: any = AuthStore.data_role
   const [form] = Form.useForm()
   const { Title, Text } = Typography
   const [attribute, setAttribute] = useState([])

   const [dataSource, setDataSource] = useState<DataType[]>([])

   const [count, setCount] = useState(15)
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      getListWarehouse()
      // handleSetDataDefault();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data])

   const getListWarehouse = async () => {
      // if (size(data) > 0 && data.length === 1) {
      //  await  OrdersStore.getListStores(data[0]?.id)
      // }
      if (size(data) > 0 && data.length) {
         const orderIds = data.map((o: any) => o.id)
         await OrdersStore.getListStoreMultiOrder({ orderIds })
      }

      if (!OrdersStore.listStores.length) {
         onCancel()
         showMessageError('Không có kho nào đủ hàng để đặt các đơn này.Vui lòng thử lại với ít đơn hàng hơn.')
      }
   }

   const filteredOptions = () => {
      const listSkuData = dataSource.map((data) => data.sku)
      //@ts-ignore
      const options = attribute.filter((item) => !listSkuData.includes(item?.label))
      return options
   }

   const handleDelete = (key: React.Key) => {
      const newData = dataSource.filter((item) => item.key !== key)
      setDataSource(newData)
   }

   const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
      {
         title: 'Order Code',
         dataIndex: 'code',
         key: 'code',
         width: 50
      },
      {
         title: 'Seller OrderID',
         dataIndex: 'sellerOrderId',
         key: 'sellerOrderId',
         width: 50
      },
      {
         title: 'Customer Information',
         dataIndex: 'customer',
         key: 'customer',
         editable: false,
         width: 50,
         render: (value: any, record: any) => {
            return record?.shipLabel ? (
               <span className=" txtMax2Line" style={{ fontSize: 11 }}>
                  {record?.shipLabel}
               </span>
            ) : (
               <div style={{ fontSize: 11 }}>
                  <div>
                     Customer:{' '}
                     <span className="gx-link txtMax2LineHover" style={{ fontSize: 11 }}>
                        {record?.customer}
                     </span>
                  </div>
                  <div>
                     Phone:{' '}
                     <span className="gx-link txtMax2LineHover" style={{ fontSize: 11 }}>
                        {record?.phone}
                     </span>
                  </div>
                  <div>
                     Address:{' '}
                     <span
                        className="gx-link txtMax2LineHover"
                        style={{ fontSize: 11 }}
                     >{`${record?.address1}- ${record?.city} - ${record?.state} - ${record?.zipCode}`}</span>
                  </div>
                  {/* <div>
                     Address1:{' '}
                     <span
                        className="gx-link txtMax2LineHover"
                        style={{ fontSize: 11 }}
                     >{`${record?.address1}`}</span>
                  </div> */}
               </div>
            )
         }
      },
      {
         title: 'Quantity',
         dataIndex: 'qty',
         key: 'qty',
         editable: false,
         width: 50
      },
      {
         title: 'Total',
         dataIndex: 'subTotalPrice',
         key: 'subTotalPrice',
         editable: false,
         width: 50,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value)}</span>
         }
      },
      {
         title: 'Status',
         dataIndex: 'status',
         key: 'status',
         width: 50,
         render: (value: any) => {
            return <span className="txtMax2Line">{getStatusOrder(value)}</span>
         }
      },
      {
         title: 'Created',
         dataIndex: 'createdAt',
         key: 'createdAt',
         width: 50,
         render: (value: any) => {
            return <span className="txtMax2Line">{moment(value).format(DATE_TIME_FORMAT_CLIENT_short)}</span>
         }
      }
      // {
      //    title: 'Base cost',
      //    dataIndex: 'totalPrice',
      //    key: 'totalPrice',
      //    editable: false,
      //    width: 50
      // },
      // {
      //    title: 'Shipping Cost',
      //    dataIndex: 'shippingFee',
      //    key: 'shippingFee',
      //    editable: false,
      //    width: 50
      // },

      // {
      //    title: 'operation',
      //    dataIndex: 'operation',
      //    fixed: 'right',
      //    width: 50,
      //    render: (_, record: any) =>
      //       dataSource.length >= 1 ? (
      //          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
      //             <a>Delete</a>
      //          </Popconfirm>
      //       ) : null
      // }
   ]

   const handleAdd = () => {
      const newData: DataType = {
         key: count,
         sku: (
            <Select
               style={{ width: '100%' }}
               showSearch
               onChange={(value) => handleChange(value as string, count)}
               placeholder="Search to Select"
               optionFilterProp="children"
               filterOption={(input, option) => ((option?.label as string) ?? '').includes(input)}
               filterSort={(optionA, optionB) =>
                  ((optionA?.label as string) ?? '')
                     .toLowerCase()
                     .localeCompare(((optionB?.label as string) ?? '').toLowerCase())
               }
               options={filteredOptions()}
            />
         ),
         name: '',
         attribute: '',
         quantity: null
      }
      setDataSource((dataSource) => [...dataSource, newData])
      setCount((count) => count + 1)
   }
   const handleChange = (value: string, key: number) => {
      //@ts-ignore
      const obj = attribute.find((item) => item?.value == value)

      setDataSource((dataSource) => {
         const newState: any = dataSource?.map((item) =>
            item.key === key
               ? //@ts-ignore
                 { key: key, sku: obj?.label, name: obj?.label, attribute: obj?.label, quantity: 1 }
               : item
         )
         return newState
      })
   }
   const handleSave = (row: DataType) => {
      const newData = [...dataSource]
      const index = newData.findIndex((item) => row.key === item.key)
      const item = newData[index]
      //@ts-ignore
      newData.splice(index, 1, { ...item, ...row })
      setDataSource(newData)
   }

   const components = {
      body: {
         row: EditableRow,
         cell: EditableCell
      }
   }

   const columns = defaultColumns.map((col) => {
      if (!col.editable) {
         return col
      }
      return {
         ...col,
         onCell: (record: DataType) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave
         })
      }
   })

   const onFinish = async (values: any) => {
      setLoading(true)
      let formData = null
      const listOrderIds = data?.map((o: any) => o.id)
      formData = {
         storeId: values.storeId,
         orderIds: listOrderIds
      }

      const result = await OrdersStore.assigneeOrder(formData)
      if (result) {
         onOk()
         form.resetFields()
      }
      setLoading(false)
   }

   return (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1200px' }}
         visible={visible}
         centered
         title={'Gán kho vận chuyển'}
         okText={t(GLOBAL_CLIENT.assignWarehouse)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         <ModalWrapper>
            <Loading isLoading={loading}></Loading>
            <Form
               {...formItemLayout}
               form={form}
               name="AssignWarehouse"
               onFinish={debounce(onFinish, 400)}
               scrollToFirstError
            >
               <div style={{ marginBottom: '10px' }}>
                  <Text style={{ marginRight: '5px' }}>Danh sách đơn hàng</Text>
                  {/* <Button type="primary" size="small" shape="circle" icon={<PlusOutlined />} onClick={handleAdd} /> */}
               </div>
               <Table
                  style={{ marginBottom: '20px' }}
                  components={components}
                  pagination={false}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={data}
                  columns={columns as ColumnTypes}
                  scroll={{ x: 1000, y: 240 }}
               />
               <Form.Item labelAlign="left" name="storeId" label={'Kho vận chuyển'} required>
                  <Select placeholder="Chọn kho">
                     {OrdersStore.listStores &&
                        OrdersStore.listStores?.map((item: any) => {
                           return (
                              <Select.Option key={item?.id} value={item?.bane}>
                                 {item?.name}
                              </Select.Option>
                           )
                        })}
                  </Select>
               </Form.Item>
            </Form>
         </ModalWrapper>
      </Modal>
   )
})

export default AssignWarehouse
