import { useEffect, useState } from 'react'
import { Input, Select, Modal, Form, Button, Col, Row, Typography, Divider, Space, Radio, Steps } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { formatCurrency, validatePhoneUS, validateEmail, showMessageError } from '../../helpers/functions'
import { useStore } from '../../hooks'
import { getPage, PAGE_SIZE, ROLES, statusOrder } from '../../helpers/constants'
import { debounce, size, trim } from 'lodash'
import styled from 'styled-components'
import CardItems from 'src/common/components/CardItems'
import { AMERICA_STATES } from '../../helpers/constants'

import useModal from 'src/helpers/modal/useModal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Loading } from 'src/common'
import moment from 'moment'
const { Option } = Select

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
      md: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
      md: { span: 16 }
   }
}

export const ModalAssignOrderWapper = styled.div`
   .expense-wrapper {
      border-left: 1px solid #e8e8e8;
   }

   label[for='CreatorOrder_shippingOption']:after {
      content: '';
   }

   @media screen and (max-width: 991px) {
      .expense-wrapper {
         border-left: 1px solid transparent;
      }
   }
`
const CreatorOrderScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, onlyView, onShowProducts, fieldFilter, textSearch, onRemove, onReloadData } =
      props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isChangePassword, setIsChangePassword] = useState(false)

   const UserStore = useStore('UserStore')
   const { rows: listSeller } = UserStore.listSeller
   const [sellerId, setSellerId] = useState('')
   const [status, setStatus] = useState(parseInt(item?.status))
   const OrdersStore = useStore('OrdersStore')
   const AuthStore = useStore('AuthStore')
   const { data_getUserInfo } = AuthStore
   const { shippingFee, listHistory } = OrdersStore
   const role: any = AuthStore.data_role
   const [form] = Form.useForm()
   const { Title, Text } = Typography
   const [shippingRadio, setShippingRadio] = useState(1)
   const [isTrackingIdChanged, setIsTrackingIdChanged] = useState(false)
   const { confirm } = useModal()
   const [loading, setLoading] = useState(false)

   
   useEffect(() => {
      if (onlyView) {
         if (!item?.shipLabel) return setShippingRadio(2)
      }
      // OrdersStore.setProductsSelected([])
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (listSeller.length == 0 && data_getUserInfo.role !== ROLES.SELLER) {
         getListSeller()
      }
      if (item?.id) {
         getListHistory()
      }
      console.log('-----82item',item);
   }, [])
   useEffect(() => {
      
      if (item?.id) {
         getListHistory()
      }
      console.log('-----82item',item);
   }, [])

   useEffect(() => {
      if (OrdersStore.productsSelected.length === 0) return OrdersStore.setShippingFee(0)

      fetchShippingFee()
   }, [
      OrdersStore.productsSelected,
      OrdersStore.productsSelected.map((product: any) => product.qty).join(','),
      shippingRadio
   ])

   const fetchShippingFee = async () => {
      try {
         const sellerId = OrdersStore.sellerId || data_getUserInfo.id
         const listProduct = OrdersStore.productsSelected.map((product: any) => ({
            id: product.id,
            qty: product.qty ? product.qty : 1
         }))

         const params = {
            sellerId,
            listProduct,
            isLabel: shippingRadio === 1 ? true : false
         }
         await OrdersStore.getShippingFee(params)
      } catch (error) {
         console.error(error)
      }
   }

   const getListSeller = async () => {
      try {
         // setIsLoading(true)
         const offset = getPage(0)
         const params = { offset }
         // console.log('params', params)

         await UserStore.getSeller(params)
      } catch (error) {
         // console.log(error)
      } finally {
      }
   }
   const getListHistory = async () => {
      try {
         await OrdersStore.getListHistory(item?.id)
      } catch (error) {
         // console.log(error)
      } finally {
      }
   }

   const handleRemoveProduct = (index: number) => {
      OrdersStore.removeProduct(index)
   }

   const onFinish = async (values: any) => {
      try {
         setLoading(true)
         const {
            sellerOrderId,
            customer,
            shipLabel,
            phone,
            country,
            state,
            city,
            trackingId,
            zipCode,
            address1,
            address2,
            email,
            linkDesign,
            saigonId
         } = values
         let formData = null
         if (!onlyView) {
            const listProduct = OrdersStore.productsSelected.map((product: any) => ({
               id: product.id,
               qty: product.qty ? product.qty : 1
            }))

            if (shippingRadio === 1) {
               formData = {
                  listProduct,
                  sellerOrderId,
                  linkDesign,
                  shipLabel,
                  trackingId,
                  sellerId: OrdersStore.sellerId || data_getUserInfo.id
               }
            } else {
               formData = {
                  listProduct,
                  sellerOrderId,
                  linkDesign,
                  customer,
                  phone,
                  country,
                  state,
                  city,
                  zipCode,
                  address1,
                  address2,
                  email,
                  trackingId,
                  sellerId: OrdersStore.sellerId || data_getUserInfo.id
               }
            }
         }
         if (email) {
            if (!validateEmail(email)) {
               showMessageError('Email không đúng định dạng!')
               return
            }
         }
         const res = onlyView
            ? await OrdersStore.updateTrackingId(item.id, trackingId, saigonId, sellerOrderId)
            : await OrdersStore.createOrder(formData)

         if (res.code === 200) {
            OrdersStore.setProductsSelected([])
            OrdersStore.setSellerId(null)
            onOk()
         }
      } catch (error) {
         console.log(error)
      } finally {
         setLoading(false)
      }
   }

   const handleConfirmOrder = (value: any) => {
      confirm({
         title: 'Bạn muốn xác nhận đơn hàng?',
         icon: <ExclamationCircleOutlined />,
         okText: 'Xác nhận',
         cancelText: 'Từ chối',
         async onCancel() {
            await OrdersStore.confirmOrder({ reject: true, orderIds: [value?.id] })
            onReloadData()
            onCancel()
         },
         async onOk() {
            await OrdersStore.confirmOrder({ reject: false, orderIds: [value?.id] })
            onReloadData()
            onCancel()
         }
      })
   }
   const handleCancelOrder = (value: any) => {
      confirm({
         title: 'Bạn xác nhận Huỷ đơn hàng?',
         icon: <ExclamationCircleOutlined />,
         okText: 'Xác nhận',
         cancelText: 'Hủy',
         async onCancel() { },
         async onOk() {
            await OrdersStore.cancelOrder(value?.id)
            onReloadData()
            onCancel()
         }
      })
   }
   const handleCompleteOrder = async (value: any) => {
      // confirm({
      //    title: 'Bạn xác nhận hoàn thành đơn hàng?',
      //    icon: <ExclamationCircleOutlined />,
      //    okText: 'Hoàn thành',
      //    cancelText: 'Hủy',
      //    async onCancel() {
      //       // await OrdersStore.cancelOrder(value?.id)
      //       // if (size(trim(textSearch)) > 0) {
      //       //    getListData(0, { ...fieldFilter, ...{ name: trim(textSearch) } })
      //       // } else {
      //       //    getListData(0, { ...fieldFilter })
      //       // }
      //       // onCancel()
      //    },
      //    async onOk() {
      try {
         await form.validateFields()
         // await onFinish(form.getFieldsValue())
         await OrdersStore.finishOrder(value?.id)
         onReloadData()
         onCancel()
      } catch (error) {
         console.error(error)
      }
      //    }
      // })
   }

   const handleRefund = () => {
      confirm({
         title: 'Bạn muốn hoàn trả đơn hàng?',
         icon: <ExclamationCircleOutlined />,
         okText: 'Xác nhận',
         cancelText: 'Hủy',
         async onCancel() { },
         async onOk() {
            try {
               await OrdersStore.refundOrder(item.id)
               onReloadData()
               onCancel()
            } catch (error) {
               console.error(error)
            }
         }
      })
   }
   const handlePushOrder = () => {
      confirm({
         title: 'Bạn muốn đẩy đơn hàng?',
         icon: <ExclamationCircleOutlined />,
         okText: 'Xác nhận',
         cancelText: 'Hủy',
         async onCancel() { },
         async onOk() {
            try {
               let ids = []
               ids.push(item?.id)
               await OrdersStore.pushOrders(ids)
               onReloadData()
               onCancel()
            } catch (error) {
               console.error(error)
            }
         }
      })
   }
   const detectFields = (sampleFormat: any, sampleData: any) => {
      if (!sampleData) return form.resetFields()

      const formatLines = sampleFormat.trim().split('\n')
      const dataLines = sampleData.trim().split('\n')

      for (let i = 0; i < formatLines.length; i++) {
         const formatField = formatLines[i].trim()
         const dataLine = dataLines[i]?.trim()
         if (formatField === 'Name' && dataLine) {
            form.setFieldsValue({
               customer: dataLine
            })
         } else if (formatField === 'Phone' && dataLine) {
            form.setFieldsValue({
               phone: dataLine
            })
         } else if (formatField === 'Address1' && dataLine) {
            form.setFieldsValue({
               address1: dataLine
            })
         } else if (formatField === 'City,State,Country' && dataLine) {
            const [city, state, country] = dataLine.split(',').map((item: any) => item.trim())
            form.setFieldsValue({
               city: city,
               state: AMERICA_STATES.find((st) => st.label === state)?.value
            })
         } else if (formatField === 'zipCode' && dataLine) {
            form.setFieldsValue({
               zipCode: dataLine
            })
         }
      }
   }

   const sampleFormat = `
   Name
   Phone
   Address1
   City,State,Country
   zipCode
   `

   const sampleData = `
   Michael Cole
   (+1)6099702469
   215 Chinkapin Avenue
   Williamstown,New Jersey,United States
   08094
   `

   const productsPrice = () => {
      const price = OrdersStore.productsSelected?.reduce(
         (acc: any, curr: any) => acc + (curr.base_cost ? curr.base_cost : 0) * (curr.qty ? curr.qty : 1),
         0
      )
      return parseFloat(price ? price : 0)
   }

   const handlingFees = () => {
      const price = OrdersStore.productsSelected?.reduce((acc: any, curr: any) => acc + curr.handing_fee, 0)
      return parseFloat(price ? price : 0)
   }
   const totalPrice = (): number => {
      const total =
         shippingRadio === 1 ? productsPrice() + handlingFees() : productsPrice() + handlingFees() + shippingFee
      return total
   }

   const feeByRole = (fee: string): number => {
      const isRoleFulfilment = data_getUserInfo?.role === ROLES.FULFILLMENT
      const feeName = isRoleFulfilment ? fee + 'Fullfiment' : fee

      return item ? item[feeName] : 0
   }

   const handleChangeRadioShipping = (e: any) => {
      setShippingRadio(e.target.value)
      form.resetFields(['trackingId'])
   }
   const renderButtonFooter = () => {
      return (
         <Row className="flex gx-justify-content-center">
            {/* {(data_getUserInfo?.role == ROLES.ADMIN || data_getUserInfo?.role == ROLES.MANAGER_ORDER) &&
               (item.status === statusOrder.NEW || item.status === statusOrder.RE_NEW) && (
                  <>
                     <Divider type="vertical" />
                     <span onClick={() => handleAssign(item)} className="gx-link txtMax2LineHover">
                        {t(GLOBAL_CLIENT.assignWarehouse)}
                     </span>
                  </>
               )} */}
            {(data_getUserInfo?.role == ROLES.ADMIN ||
               data_getUserInfo?.role == ROLES.MANAGER_ORDER ||
               data_getUserInfo?.role == ROLES.FULFILLMENT) &&
               item?.status === statusOrder.ASSIGNEE && (
                  <>
                     {/* <Divider type="vertical" />
                     <span onClick={() => handleConfirmOrder(item)} className="gx-link txtMax2LineHover">
                        {'Xác nhận'}
                     </span> */}
                     <Button type="primary" key="back" onClick={() => handleConfirmOrder(item)}>
                        Xác nhận
                     </Button>
                  </>
               )}
            {(data_getUserInfo?.role == ROLES.ADMIN ||
               data_getUserInfo?.role == ROLES.MANAGER_ORDER ||
               data_getUserInfo?.role == ROLES.FULFILLMENT) &&
               item?.status === statusOrder.ACCEPTED && (
                  <>
                     <Button type="dashed" key="back" onClick={() => handleCancelOrder(item)}>
                        Huỷ đơn
                     </Button>
                  </>
               )}
            {(data_getUserInfo?.role == ROLES.ADMIN ||
               data_getUserInfo?.role == ROLES.MANAGER_ORDER ||
               data_getUserInfo?.role == ROLES.FULFILLMENT) &&
               item?.status === statusOrder.ACCEPTED && (
                  <>
                     {/* <Divider type="vertical" />
                     <span onClick={() => handleCompleteOrder(item)} className="gx-link txtMax2LineHover">
                        {'Hoàn thành đơn'}
                     </span> */}
                     <Button type="primary" key="back" onClick={() => handleCompleteOrder(item)}>
                        Hoàn thành
                     </Button>
                  </>
               )}
            {(data_getUserInfo?.role === ROLES.ADMIN ||
               data_getUserInfo?.role === ROLES.MANAGER_ORDER ||
               data_getUserInfo?.role === ROLES.SELLER) &&
               (item?.status === statusOrder.NEW || item?.status === statusOrder.RE_NEW) &&
               !item?.storeId && (
                  <Button type="primary" key="back" onClick={() => onRemove(item?.id)}>
                     {'Xóa'}
                  </Button>
               )}
            {(data_getUserInfo?.role === ROLES.ADMIN || data_getUserInfo.role === ROLES.MANAGER_ORDER) &&
               item?.status === statusOrder.FINISH && (
                  <Button type="primary" key="refund" onClick={handleRefund}>
                     {'Trả đơn hàng'}
                  </Button>
               )}

            {(data_getUserInfo?.role === ROLES.ADMIN || data_getUserInfo?.role === ROLES.MANAGER_ORDER) &&
               item?.storeId &&
               !item?.saigonId && (
                  <Button type="primary" key="PushOrder" onClick={handlePushOrder}>
                     {'Đẩy đơn hàng'}
                  </Button>
               )}
         </Row>
      )
   }
   const handleChangeSeller = (id: any) => {
      OrdersStore.setSellerId(id)
   }

   const handleCancel = () => {
      OrdersStore.setProductsSelected([])
      OrdersStore.setSellerId(null)
      onCancel()
   }

   const isRoleAdmin = () => {
      return data_getUserInfo?.role == ROLES.ADMIN
   }
   const isHideButton = () => {
      if (!isRoleAdmin()) return false
      if (isRoleAdmin() && (OrdersStore.sellerId || item?.accountId)) return false

      return true
   }
   const allowUpdateTrackingId = () => {
      if (role === ROLES.ADMIN || role === ROLES.FULFILLMENT || role === ROLES.SELLER) return true

      return false
   }
   const sellerSelector = () => {
      return (
         <>
            {data_getUserInfo?.role == ROLES.ADMIN && (
               <>
                  <Title level={5}>Thông tin seller</Title>

                  <Form.Item
                     name="seller"
                     label={'Seller'}
                     initialValue={item?.accountId}
                     rules={[
                        {
                           required: true,
                           message: 'Vui lòng chọn Seller'
                        }
                     ]}
                  >
                     <Select
                        value={sellerId}
                        clearIcon
                        placeholder="Chọn seller"
                        onChange={handleChangeSeller}
                        disabled={onlyView}
                     >
                        {listSeller.map((item: any) => {
                           return (
                              <Select.Option key={item?.id} value={item?.id}>
                                 {item?.name}
                              </Select.Option>
                           )
                        })}
                     </Select>
                  </Form.Item>
               </>
            )}
         </>
      )
   }

   const trackingIdChangedCheck = (e: any) => {
      const trackingId = e.target.value
      if (!trackingId) return setIsTrackingIdChanged(false)
      if (item?.trackingId === trackingId) return setIsTrackingIdChanged(false)
      return setIsTrackingIdChanged(true)
   }

   const renderContent = () => {
      return (
         <>
            <Loading isLoading={loading}></Loading>

            <ModalAssignOrderWapper>
               <Form {...formItemLayout} form={form} name="CreatorOrder" scrollToFirstError onFinish={onFinish}>
                  <Row>
                     <Col xs={24} lg={16}>
                        {sellerSelector()}
                        <Title level={5}>Sản phẩm</Title>
                        <CardItems
                           listCard={OrdersStore.productsSelected}
                           type={onlyView ? 'view' : 'edit'}
                           style={{ maxHeight: '300px', overflowY: 'scroll' }}
                           onDelete={handleRemoveProduct}
                        />
                        <Divider style={{ marginBottom: '20px' }}></Divider>
                        <div className="gx-d-flex gx-justify-content-center">
                           {!onlyView && (
                              <Button disabled={isHideButton()} onClick={onShowProducts}>
                                 Thêm sản phẩm
                              </Button>
                           )}
                        </div>

                        <Title level={5}>Thông tin đơn hàng</Title>
                        <Form.Item
                           name="sellerOrderId"
                           label={t(GLOBAL_CLIENT.sellerOrderId)}
                           initialValue={item?.sellerOrderId}
                        >
                           <Input maxLength={30} readOnly={role === ROLES.ADMIN ? false : onlyView} />
                        </Form.Item>
                        <Form.Item name="linkDesign" label={'Link Design'} initialValue={item?.linkDesign}>
                           <Input maxLength={500} readOnly={onlyView} />
                        </Form.Item>
                        {!onlyView ? (
                           <Form.Item
                              labelCol={{ sm: 10, md: 12 }}
                              name="shippingOption"
                              label={
                                 <Radio.Group value={shippingRadio} onChange={handleChangeRadioShipping}>
                                    <Radio value={1}>Ship label</Radio>
                                    <Radio value={2}>Address</Radio>
                                 </Radio.Group>
                              }
                           ></Form.Item>
                        ) : null}
                        {shippingRadio == 1 ? (
                           <Form.Item
                              name="shipLabel"
                              label={t(GLOBAL_CLIENT.shippingLabel)}
                              initialValue={item?.shipLabel}
                              rules={[
                                 {
                                    required: true,
                                    message: 'Vui lòng nhập link ship lable'
                                 }
                              ]}
                           >
                              <Input style={{ width: '100%' }} maxLength={254} readOnly={onlyView} />
                           </Form.Item>
                        ) : (
                           <>
                              {!onlyView && (
                                 <Form.Item labelCol={{ xs: 24 }} wrapperCol={{ xs: 24 }} name="addressArea" label={''}>
                                    <Input.TextArea
                                       rows={6}
                                       maxLength={254}
                                       onChange={(e) => detectFields(sampleFormat, e.target.value)}
                                       placeholder="Tiktok address form"
                                    />
                                 </Form.Item>
                              )}

                              <Form.Item
                                 name="customer"
                                 label={'Tên khách hàng'}
                                 initialValue={item?.customer}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Vui lòng nhập tên khách hàng'
                                    }
                                 ]}
                              >
                                 <Input maxLength={254} readOnly={onlyView} />
                              </Form.Item>
                              <Form.Item
                                 name="email"
                                 initialValue={item?.email}
                                 label={'Email'}
                              // rules={[
                              //    () => ({
                              //       validator(_, value) {
                              //          if (validateEmail(value)) {
                              //             return Promise.resolve()
                              //          }
                              //          return Promise.reject('Vui lòng nhập đúng định dạng email')
                              //       }
                              //    })
                              // ]}
                              >
                                 <Input style={{ width: '100%' }} maxLength={254} readOnly={onlyView} />
                              </Form.Item>

                              <Form.Item
                                 name="phone"
                                 initialValue={item?.phone}
                                 label={'Số điện thoại'}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Vui lòng nhập số điện thoại'
                                    }
                                    // () => ({
                                    //    validator(_, value) {
                                    //       if (validatePhoneUS(value)) {
                                    //          return Promise.resolve()
                                    //       }
                                    //       return Promise.reject('Vui lòng nhập đúng định dạng số điện thoại')
                                    //    }
                                    // })
                                 ]}
                              >
                                 <Input style={{ width: '100%' }} maxLength={254} readOnly={onlyView} />
                              </Form.Item>
                              <Form.Item
                                 name="country"
                                 initialValue={'United States'}
                                 label={'Quốc gia'}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Vui lòng nhập tên quốc gia'
                                    }
                                 ]}
                              >
                                 <Input style={{ width: '100%' }} maxLength={254} readOnly />
                              </Form.Item>
                              <Form.Item
                                 name="state"
                                 label={'Tiểu bang'}
                                 initialValue={item?.state}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Vui lòng chọn tiểu bang'
                                    }
                                 ]}
                              >
                                 <Select
                                    disabled={onlyView}
                                    showSearch
                                    placeholder="Chọn tiểu bang"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => ((option?.label as string) ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                       ((optionA?.label as string) ?? '')
                                          .toLowerCase()
                                          .localeCompare(((optionB?.label as string) ?? '').toLowerCase())
                                    }
                                    options={AMERICA_STATES}
                                 />
                              </Form.Item>
                              <Form.Item
                                 name="address1"
                                 label={'Address 1'}
                                 initialValue={item?.address1}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Vui lòng nhập address '
                                    }
                                 ]}
                              >
                                 <Input style={{ width: '100%' }} maxLength={254} readOnly={onlyView} />
                              </Form.Item>
                              <Form.Item name="address2" label={'Address 2'} initialValue={item?.address2}>
                                 <Input style={{ width: '100%' }} maxLength={254} readOnly={onlyView} />
                              </Form.Item>
                              <Form.Item
                                 name="city"
                                 label={'Thành phố'}
                                 initialValue={item?.city}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Vui lòng nhập City'
                                    }
                                 ]}
                              >
                                 <Input style={{ width: '100%' }} maxLength={254} readOnly={onlyView} />
                              </Form.Item>
                              <Form.Item
                                 name="zipCode"
                                 initialValue={item?.zipCode}
                                 label={'Zip/Post code'}
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Vui lòng nhập zip code'
                                    }
                                 ]}
                              >
                                 <Input style={{ width: '100%' }} maxLength={11} readOnly={onlyView} />
                              </Form.Item>
                           </>
                        )}
                        {allowUpdateTrackingId() && (
                           <Form.Item
                              name="trackingId"
                              label={'TrackingID'}
                              initialValue={item?.trackingId}
                              rules={[
                                 {
                                    required: shippingRadio === 1,
                                    message: 'Vui lòng nhập TrackingID'
                                 }
                              ]}
                           >
                              <Input style={{ width: '100%' }} maxLength={254} onChange={trackingIdChangedCheck} />
                           </Form.Item>
                        )}
                        {onlyView && role === ROLES.ADMIN && item?.storeId && !item?.saigonId && (
                           <Form.Item name="saigonId" label={'Saigon ID'} initialValue={item?.saigonId}>
                              <Input style={{ width: '100%' }} maxLength={254} onChange={trackingIdChangedCheck} />
                           </Form.Item>
                        )}
                        {!item?.isPending && renderButtonFooter()}

                        {onlyView && item?.id && listHistory && listHistory.length > 0 &&
                           <>
                              <Title level={5} style={{ marginTop: 20 }}>Lịch sử hoạt động</Title>
                              <Steps direction="vertical" size="small">
                                 {listHistory && listHistory.map((item: any,idx:number) => {
                                    return (<Steps.Step key={idx} title={item?.description} description={moment(item?.createdAt).format('HH:mm DD/MM/YYYY')} />)
                                 })}
                              </Steps>
                           </>}
                     </Col>
                     <Col xs={24} lg={8} className="expense-wrapper">
                        <Title level={5}>Chi phí vận đơn</Title>
                        <Space direction="vertical" style={{ width: '100%' }}>
                           {role !== ROLES.FULFILLMENT && (
                              <Row justify="space-between" style={{ margin: '0 10px' }}>
                                 <span>Giá sản phẩm</span>
                                 <span>
                                    {onlyView
                                       ? '$' + formatCurrency(item?.totalPrice)
                                       : '$' + formatCurrency(productsPrice())}
                                 </span>
                              </Row>
                           )}

                           {data_getUserInfo.role == ROLES.FULFILLMENT && (
                              <Row justify="space-between" style={{ margin: '0 10px' }}>
                                 <span>Phí đóng gói</span>
                                 <span>{onlyView && '$' + formatCurrency(feeByRole('packingFee'))}</span>
                              </Row>
                           )}
                           <Row justify="space-between" style={{ margin: '0 10px' }}>
                              <span>Phí quản lý đơn</span>
                              <span>
                                 {onlyView
                                    ? '$' + formatCurrency(feeByRole('handingFee'))
                                    : '$' + formatCurrency(handlingFees())}
                              </span>
                           </Row>
                           {(role !== ROLES.FULFILLMENT || (role === ROLES.FULFILLMENT && shippingRadio !== 1)) && (
                              <Row justify="space-between" style={{ margin: '0 10px' }}>
                                 <span>{shippingRadio === 1 ? 'Phí label' : 'Phí ship'}</span>
                                 <span>
                                    {onlyView
                                       ? '$' + formatCurrency(feeByRole('shippingFee'))
                                       : '$' + formatCurrency(shippingFee)}
                                 </span>
                              </Row>
                           )}
                           {/* {role == ROLES.FULFILLMENT && shippingRadio !== 1 && (
                           <Row justify="space-between" style={{ margin: '0 10px' }}>
                              <span>{shippingRadio === 1 ? 'Phí label' : 'Phí ship'}</span>
                              <span>
                                 {onlyView
                                    ? '$' + formatCurrency(feeByRole('shippingFee'))
                                    : '$' + formatCurrency(shippingFee)}
                              </span>
                           </Row>
                        )} */}
                           <Divider></Divider>
                           <Row justify="space-between" style={{ margin: '0 10px' }}>
                              <span>Tổng tiền</span>
                              <span>
                                 {onlyView
                                    ? '$' + formatCurrency(feeByRole('subTotalPrice'))
                                    : '$' + formatCurrency(totalPrice())}
                              </span>
                           </Row>
                        </Space>
                     </Col>
                  </Row>
               </Form>
            </ModalAssignOrderWapper>
         </>
      )
   }

   return !onlyView ? (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1000px' }}
         visible={visible}
         centered
         title={'Tạo đơn'}
         okText={t(GLOBAL_CLIENT.create)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={handleCancel}
         onOk={form.submit}
         footer={[
            <Button key="back" onClick={handleCancel}>
               {t(GLOBAL_CLIENT.close)}
            </Button>,
            <Button
               key="submit"
               type="primary"
               onClick={form.submit}
               disabled={OrdersStore.productsSelected.length === 0}
            >
               {t(GLOBAL_CLIENT.create)}
            </Button>
         ]}
      >
         {renderContent()}
      </Modal>
   ) : (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1000px' }}
         visible={visible}
         centered
         title={'Chi tiết đơn ' + item?.code}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={handleCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>

               {allowUpdateTrackingId() && !item?.isPending && (
                  <Button key="submit" type="primary" disabled={!isTrackingIdChanged} onClick={form.submit}>
                     Cập nhật
                  </Button>
               )}
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default CreatorOrderScreen
