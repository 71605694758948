import { useEffect, useState } from 'react'
import { Table, Space, Pagination, Col, Row, Breadcrumb, Button, DatePicker, Divider } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { isEmpty, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import {
   DATE_FORMAT_CLIENT,
   DATE_FORMAT_CLIENT_PARAMS,
   getStatusBill,
   PAGE_SIZE,
   TYPE_FUND
} from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import moment from 'moment'
import 'moment/locale/vi'
import useWindowDimensions from '../../common/useWindowDimensions'
import { getPage, typeFund } from 'src/helpers/constants'
import TopupAdminModal from 'src/common/components/topup/TopupAdminModal'
import useModal from 'src/helpers/modal/useModal'
import Product from './Product'
import { formatCurrency } from 'src/helpers/functions'
import ProductImport from './ProductImport'
import locale from 'antd/es/date-picker/locale/vi_VN'
import CreateOrder from '../order/Create'
import ProductImportDetail from './ProductImportDetail'
const TYPE_SCREEN = { bill: '1', list_product: '2' }
const IMPORT_TO_MAIN_STORE = 0
const EXPORT_PRODUCT_FROM_MAIN_TO_FULLFIMENT = 1
const EXPORT_PRODUCT_FROM_FULLFIMENT_TO_CUSTOMER = 2
const { RangePicker } = DatePicker

const Bill = observer((props: any) => {
   const { stores, showWarehouse } = props
   const { t } = useTranslation()
   const { confirm } = useModal()
   const FundStore = useStore('FundStore')
   const [textSearch, set_textSearch] = useState('')
   const [type, setType] = useState(TYPE_FUND.TAT_CA)
   const { height } = useWindowDimensions()
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const [startTime, setStartTime] = useState(moment().subtract(1, 'months'))
   const [endTime, setEndTime] = useState(moment())
   const WarehouseStore = useStore('WarehouseStore')
   const [item, setItem] = useState(null)
   const [topup, setTopup] = useState(null)
   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [isTopupModalOpen, setIsTopupModalOpen] = useState(false)
   const { before, import: importValue, export: exportValue, inventory } = WarehouseStore.priceWarehouse
   const { rows, offset, count } = FundStore.importExportList
   const [visibleView, setVisibleView] = useState<any>(TYPE_SCREEN.bill)
   const [visibleImportProduct, setVisibleImportProduct] = useState<any>(false)
   const [visibleExportProduct, setVisibleExportProduct] = useState<any>(false)
   const [visibleImportFulfiment, setVisibleImportFulfiment] = useState(false)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [selectedItem, setSelectedItem] = useState<any>([])
   const OrdersStore = useStore('OrdersStore')
   const [visibleImportDetail, setVisibleImportDetail] = useState<any>(false)

   useEffect(() => {
      getListData(0, {
         // text: trim(textSearch),
         // from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         // to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
      WarehouseStore.getTotalPrice(
         moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         stores.id
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [startTime, endTime])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param }
         //  console.log('params', params)

         await FundStore.getImportExportList(stores.id, params)
      } catch (error) {
         //  console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      getListData(currentPage - 1)
   }

   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const handleConfirm = (value: any) => {
      // console.log('handleConfirm: ', value)

      setTopup(value)
      if (value?.type === typeFund.WITHDRAW) {
         setIsTopupModalOpen(true)
      } else {
         confirm({
            title: t(GLOBAL_CLIENT.banchapnhanphieuyeucaunay),
            icon: <ExclamationCircleOutlined />,
            // content: t(GLOBAL_CLIENT.logoutDescription),
            okText: t(GLOBAL_CLIENT.apply),
            cancelText: t(GLOBAL_CLIENT.huy),
            onCancel() { },
            async onOk() {
               await FundStore.approveFund(value?.id, { reject: false })
               getListData(0, {
                  text: trim(textSearch),
                  from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
                  to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
               })
            }
         })
      }
   }

   const onRefreshList = () => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
   }

   const handleCloseTopup = () => {
      setIsTopupModalOpen(false)
   }
   const columns = [
      {
         title: 'Ngày',
         dataIndex: 'createdAt',
         key: 'createdAt',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{moment(value).format('HH:mm DD/MM/YYYY')}</span>
         }
      },
      {
         title: 'Mã phiếu',
         dataIndex: 'id',
         key: 'id',
         width: 80,
         render: (value: any, record: any) => {
            return record.order?.code ? (
               <span
                  onClick={() => showDetailOrder(record)}
                  style={{ textDecoration: 'underline' }}
                  className="gx-link txtMax2Line"
               >
                  {record.order?.code}
               </span>
            ) : (
               <span className="txtMax2Line"> {value}</span>
            )
         }
      },
      {
         title: 'Loại phiếu',
         dataIndex: 'type',
         key: 'type',
         width: 150,
         render: (value: any, record: any) => {
            return <span className="txtMax2Line">{getStatusBill(value, { stores: WarehouseStore.warehouseList?.rows,fromStoreId:record?.fromStoreId, toStoreId: record?.toStoreId })}</span>
         }
      },
      // {
      //    title: 'Đơn vị',
      //    dataIndex: 'description',
      //    key: 'description',
      //    width: 120,
      //    render: (value: any) => {
      //       return <span className="txtMax2Line">{value}</span>
      //    }
      // },
      {
         title: 'Số lượng nhập',
         dataIndex: 'qty',
         key: 'qty',
         width: 120,
         render: (value: any, record: any) => {
            return (
               <span className="txtMax2Line">
                  {(stores.name !== 'MAIN_STORE' && record?.type === EXPORT_PRODUCT_FROM_MAIN_TO_FULLFIMENT) ||
                     (stores.name === 'MAIN_STORE' && record?.type === IMPORT_TO_MAIN_STORE)
                     ? value
                     : ''}
               </span>
            )
         }
      },
      {
         title: 'Số lượng xuất',
         dataIndex: 'qty',
         key: 'qty',
         width: 120,
         render: (value: any, record: any) => {
            return (
               <span className="txtMax2Line">
                  {(stores.name !== 'MAIN_STORE' && record?.type === EXPORT_PRODUCT_FROM_FULLFIMENT_TO_CUSTOMER) ||
                     (stores.name === 'MAIN_STORE' && record?.type === EXPORT_PRODUCT_FROM_MAIN_TO_FULLFIMENT)
                     ? value
                     : ''}
               </span>
            )
         }
      },
      {
         title: 'Tổng tiền',
         dataIndex: 'totalPrice',
         key: 'totalPrice',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{formatCurrency(value, true)}đ</span>
         }
      },
      {
         title: 'action',
         key: 'action',
         dataIndex: 'updatedAt',
         width: 120,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (text: string, record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showProduct(record)} className="gx-link">
                     {'Danh sách sản phẩm'}
                  </span>
                  <Divider type="vertical" />
                  {(stores.name === 'MAIN_STORE' ||
                     (stores.name !== 'MAIN_STORE' && record?.type !== EXPORT_PRODUCT_FROM_FULLFIMENT_TO_CUSTOMER)) && (
                        <span onClick={() => showDetail(record)} className="gx-link">
                           {'Chi tiết'}
                        </span>
                     )}
               </span>
            )
         }
      }
   ]

   const visibleColumns = stores.name !== 'MAIN_STORE' ? columns.filter((c) => c.key !== 'totalPrice') : columns

   const showBill = () => {
      setVisibleView(TYPE_SCREEN.bill)
      // setTimeout(() => {
      //    setVisibleDetail(true)
      // }, 500)
   }
   const showProduct = (value: any) => {
      setDetailInfo(value)
      setVisibleView(TYPE_SCREEN.list_product)
      // setTimeout(() => {
      //    setVisibleDetail(true)
      // }, 500)
   }

   const showDetail = (record: any) => {
      setSelectedItem(record)
      setVisibleImportDetail(true)
   }
   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   const handleRangePickerChange = (date: any) => {
      // set_dateRangerPicker(date);
      //  console.log('datee: ', date)
      setStartTime(date[0])
      setEndTime(date[1])
   }
   const handleExport = () => {
      setVisibleExportProduct(true)
   }

   const handleImport = () => {
      setVisibleImportProduct(true)
   }
   const handleImportFulfiment = () => {
      setVisibleImportFulfiment(true)
   }

   const handleImportExportOk = (isExport = false) => {
      // export main store == import fulfillment
      if (isExport && stores.name !== 'MAIN_STORE') {
         setVisibleImportFulfiment(false)
      } else if (isExport) {
         setVisibleExportProduct(false)
      } else {
         setVisibleImportProduct(false)
      }

      getListData(0)
   }
   const handleImportExportDetailOk = () => {
      setVisibleImportDetail(false)
      WarehouseStore.getTotalPrice(
         moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         stores.id
      )
      getListData(0)
   }

   const handleImportExportCancel = (isExport = false) => {
      // export main store == import fulfillment
      if (isExport && stores.name !== 'MAIN_STORE') {
         setVisibleImportFulfiment(false)
      } else if (isExport) {
         setVisibleExportProduct(false)
      } else {
         setVisibleImportProduct(false)
      }

      WarehouseStore.setProductsSelected([])
   }

   const handleImportExportDetailCancel = (isExport = false) => {
      // // export main store == import fulfillment
      // if (isExport && stores.name !== 'MAIN_STORE') {
      //    setVisibleImportFulfiment(false)
      // } else if (isExport) {
      //    setVisibleExportProduct(false)
      // } else {
      //    setVisibleImportProduct(false)
      // }

      setVisibleImportDetail(false)

      WarehouseStore.setProductsSelected([])
   }

   const showDetailOrder = async (item: any) => {
      if (item?.order?.id) {
         try {
            const result = await OrdersStore.getDetail(item?.order?.id)
            setSelectedItem(result?.order)
            const listProduct = formatDataProducts(result?.listProductInOrder)
            OrdersStore.setProductsSelected(listProduct)
            setVisibleDetail(true)
         } catch (error) {
            console.error(error)
         }
      }
   }

   const formatDataProducts = (list: any) => {
      return list.map((item: any) => {
         return {
            name: item.product.name,
            id: item.product.id,
            images: item.product.images,
            base_cost: item.baseCostSellter,
            qty: item.qty,
            tags: item.product.tags
         }
      })
   }

   const renderHeader = () => {
      return (
         <Row
            className="row-container-head"
            style={{ background: '#fff', padding: 12, borderRadius: 6, marginLeft: 0, marginRight: 0 }}
         >
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Đầu kỳ</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#6998B9', fontWeight: 'bolder' }}>
                     {formatCurrency(before, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>+</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tổng nhập</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#89AD88', fontWeight: 'bolder' }}>
                     {formatCurrency(importValue, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>-</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tổng xuất</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#B87986', fontWeight: 'bolder' }}>
                     {formatCurrency(exportValue, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>=</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tồn kho</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#3F74A1', fontWeight: 'bolder' }}>
                     {formatCurrency(inventory, true)}
                  </span>
               </Col>
            </Col>
         </Row>
      )
   }

   return visibleView == TYPE_SCREEN.bill ? (
      <>
         <Loading isLoading={isLoading} />
         <Row>
            <Col span={24}>
               <Breadcrumb separator=">">
                  {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
                  <Breadcrumb.Item>
                     <span className="gx-link" style={{ textDecoration: 'underline' }} onClick={() => showWarehouse()}>
                        Kho hàng
                     </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="gx-link">{stores?.name}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                     <span>Danh sách phiếu</span>
                  </Breadcrumb.Item>
               </Breadcrumb>

               {/* <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.txtSearch)}
                        allowClear
                     />
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           {renderFilter()}
                           <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />
                              {t(GLOBAL_CLIENT.create)}
                           </Button>
                        </Space>
                     </div>
                  </Col>
               </Row> */}

               <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     <div className="text-left">
                        <Space>
                           {/* <RangePicker
                              allowClear={false}
                              defaultValue={[startTime, endTime]}
                              format={DATE_FORMAT_CLIENT}
                              locale={locale}
                              onChange={handleRangePickerChange}
                           /> */}
                           {/* <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />

                              {t(GLOBAL_CLIENT.create)}
                           </Button> */}
                        </Space>
                     </div>
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           <RangePicker
                              allowClear={false}
                              defaultValue={[startTime, endTime]}
                              format={DATE_FORMAT_CLIENT}
                              locale={locale}
                              onChange={handleRangePickerChange}
                           />
                           {stores.name === 'MAIN_STORE' && (
                              <>
                                 <Button onClick={handleImport}>{'Nhập hàng'}</Button>
                                 <Button onClick={handleExport}>{'Xuất hàng'}</Button>
                              </>
                           )}
                           {stores.name !== 'MAIN_STORE' && (
                              <Button onClick={handleImportFulfiment}>{'Nhập hàng'}</Button>
                           )}
                        </Space>
                     </div>
                  </Col>
               </Row>
               {renderHeader()}
               <Space className="total-items">
                  {t(GLOBAL_CLIENT.totalItems)}: {count}
               </Space>

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 280 }}
                  columns={visibleColumns}
                  dataSource={rows}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {renderPagination()}
            </Col>
         </Row>
         {isTopupModalOpen && (
            <TopupAdminModal
               isTopupModalOpen={isTopupModalOpen}
               topup={topup}
               handleCancel={handleCloseTopup}
               onRefreshList={onRefreshList}
            />
         )}
         {visibleImportProduct && (
            <ProductImport
               visible={visibleImportProduct}
               onCancel={() => handleImportExportCancel()}
               onOk={() => handleImportExportOk()}
            />
         )}
         {visibleExportProduct && (
            <ProductImport
               exportModal
               visible={visibleExportProduct}
               onCancel={() => handleImportExportCancel(true)}
               onOk={() => handleImportExportOk(true)}
            />
         )}

         {visibleImportFulfiment && (
            // export main store == import fulfillment
            <ProductImport
               fulfimentId={stores.id}
               exportModal
               visible={visibleImportFulfiment}
               onCancel={() => handleImportExportCancel(true)}
               onOk={() => handleImportExportOk(true)}
            />
         )}
         {visibleDetail && (
            <CreateOrder
               onlyView
               visible={visibleDetail}
               item={selectedItem}
               // onOk={() => handleOrderCreator(true)}
               // fieldFilter={fieldFilter}
               // textSearch={textSearch}
               onCancel={() => setVisibleDetail(false)}
            // onShowProducts={() => setVisibleProductModal(true)}
            // onRemove={(id: string) => handleRemoveOrder(id)}
            />
         )}

         {visibleImportDetail && (
            <ProductImportDetail
               item={selectedItem}
               fulfimentId={stores.id}
               visible={visibleImportDetail}
               onCancel={() => handleImportExportDetailCancel(true)}
               onOk={handleImportExportDetailOk}
            />
         )}
      </>
   ) : (
      item && <Product showWarehouse={showWarehouse} showBill={showBill} stores={stores} bill={item} />
   )
})

export default Bill
