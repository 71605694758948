import { Col, DatePicker, Layout, Row, Select, Space, Tabs } from 'antd'

import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { DATE_FORMAT_CLIENT, ROLE, FILTER_TIME, tabIDsReport } from '../../helpers/constants'

import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import ContentTab from './ContentTab'
import { useState } from 'react'

const { Content } = Layout
const TabPane = Tabs.TabPane

const ReportManagement = observer((props: any) => {
   const { t } = useTranslation()
   const DashboardStore = useStore('DashboardStore')
   const [typeFilter, setTypeFilter] = useState(FILTER_TIME[1].type)

   const handleRangePickerChange = (date: any) => {
      DashboardStore.setDate(date)
   }
   const onChangeTab = (key: string) => {
      DashboardStore.setActiveKeyReport(key)
   }

   const handleChangeSelect = (value: string) => {
      console.log('value', value)
      setTypeFilter(value)
      switch (value) {
         case FILTER_TIME[0].type:
            DashboardStore.setStartTime(FILTER_TIME[0].startTime)
            DashboardStore.setEndTime(FILTER_TIME[0].endTime)
            break
         case FILTER_TIME[1].type:
            DashboardStore.setStartTime(FILTER_TIME[1].startTime)
            DashboardStore.setEndTime(FILTER_TIME[1].endTime)
            break
         case FILTER_TIME[2].type:
            DashboardStore.setStartTime(FILTER_TIME[2].startTime)
            DashboardStore.setEndTime(FILTER_TIME[2].endTime)
            break
         case FILTER_TIME[3].type:
            DashboardStore.setStartTime(FILTER_TIME[3].startTime)
            DashboardStore.setEndTime(FILTER_TIME[3].endTime)
            break

         default:
            DashboardStore.setStartTime(FILTER_TIME[4].startTime)
            DashboardStore.setEndTime(FILTER_TIME[4].endTime)
            break
      }
   }

   const disabledDate = (current: Moment) => {
      return current > moment() || current < moment().subtract(90, 'day')
   }

   const renderType = () => {
      return (
         <>
            <Select
               defaultValue={FILTER_TIME[1].type}
               value={typeFilter}
               onChange={handleChangeSelect}
               style={{ width: 185, alignItems: 'center', textAlign: 'left' }}
            >
               {FILTER_TIME.map((item: any) => {
                  return (
                     <Select.Option key={item.type} value={item.type}>
                        {item.title}
                     </Select.Option>
                  )
               })}
            </Select>
            {
               <DatePicker.RangePicker
                  key={moment(DashboardStore.startTime).format('YYYYMMDDHHmmss')}
                  allowClear={false}
                  defaultValue={[DashboardStore.startTime, DashboardStore.endTime]}
                  format={DATE_FORMAT_CLIENT}
                  locale={locale}
                  disabled={typeFilter !== FILTER_TIME[4].type}
                  disabledDate={disabledDate}
                  onChange={handleRangePickerChange}
               />
            }
         </>
      )
   }
   return (
      <Content {...props}>
         <Row>
            <Col xs={24} md={12}></Col>
            <Col xs={24} md={12}>
               <div className="text-right">
                  <Space>{renderType()}</Space>
               </div>
            </Col>
         </Row>
         <Tabs activeKey={DashboardStore.activeKeyReport} onChange={onChangeTab}>
            <TabPane tab={'Seller'} key={tabIDsReport.seller}>
               {DashboardStore.activeKeyReport === tabIDsReport.seller && (
                  <ContentTab
                     key={tabIDsReport.seller}
                     startTime={DashboardStore.startTime}
                     endTime={DashboardStore.endTime}
                     type={0}
                     typeFilter={typeFilter}
                  />
               )}
            </TabPane>
            <TabPane tab={'Sản phẩm'} key={tabIDsReport.product}>
               {DashboardStore.activeKeyReport === tabIDsReport.product && (
                  <ContentTab
                     key={tabIDsReport.product}
                     startTime={DashboardStore.startTime}
                     endTime={DashboardStore.endTime}
                     type={1}
                     typeFilter={typeFilter}
                  />
               )}
            </TabPane>
         </Tabs>
      </Content>
   )
})

export default ReportManagement
