import { useEffect, useState } from 'react'
import { Table, Space, Button, Pagination, Col, Row, Divider, Input, Modal, DatePicker } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { isEmpty, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import {
   DATE_FORMAT_CLIENT,
   DATE_FORMAT_CLIENT_PARAMS,
   DATE_TIME_FORMAT_CLIENT,
   PAGE_SIZE,
   TYPE_FUND
} from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import Create from './Create'
import Detail from './Detail'
import moment from 'moment'
import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import useWindowDimensions from '../../common/useWindowDimensions'
import { formatCurrency } from 'src/helpers/functions'
import { getPage, getStatusThuChi, getTypeThuChi, statusFund, typeFund } from 'src/helpers/constants'
import TopupAdminModal from 'src/common/components/topup/TopupAdminModal'
import useModal from 'src/helpers/modal/useModal'
// import DetailCreator from '../creator-management/Detail'
// import DetailDesigner from '../designer-management/Detail'

const { RangePicker } = DatePicker

const FundManagement = observer(() => {
   const { t } = useTranslation()
   const { confirm } = useModal()
   const FundStore = useStore('FundStore')
   const [textSearch, set_textSearch] = useState('')
   const [type, setType] = useState(TYPE_FUND.TAT_CA)
   const { height } = useWindowDimensions()
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const [startTime, setStartTime] = useState(moment().subtract(1, 'months'))
   const [endTime, setEndTime] = useState(moment())

   const [item, setItem] = useState(null)
   const [topup, setTopup] = useState(null)
   const [visibleCreate, setVisibleCreate] = useState(false)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [isTopupModalOpen, setIsTopupModalOpen] = useState(false)

   const { rows, offset, count } = FundStore.fundList
   const { before, collect, spend, after } = FundStore.priceFund
   const [itemAccount, setItemAccount] = useState(null)
   const [visibleDetailAccount, setVisibleDetailAccount] = useState<any>(false)

   useEffect(() => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
      FundStore.getTotalPrice(
         moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, startTime, endTime])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param }
         //  console.log('params', params)

         await FundStore.getFundList(params)
      } catch (error) {
         //  console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      getListData(currentPage - 1, { text: trim(textSearch) })
   }

   const handleChangeTextSearch = (value: any) => {
      set_textSearch(value)
   }

   const handleCreate = async () => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
      setVisibleCreate(false)
   }

   // useEffect(() => {
   //    handle_getListData(page)
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [textSearch, type, sortDateDesc])

   const clearDetailInfo = () => {
      setItem(null)
   }
   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const showCreate = () => {
      // props.history.push(ROUTER_PATH.CreateEmployeeScreen);
      setVisibleCreate(true)
   }

   const showDetail = (value: any) => {
      setDetailInfo(value)
      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const handleApprove = (value: any) => {
      confirm({
         title: t(GLOBAL_CLIENT.banchapnhanphieuyeucaunay),
         icon: <ExclamationCircleOutlined />,
         okText: t(GLOBAL_CLIENT.apply),
         cancelText: t(GLOBAL_CLIENT.huy),
         onCancel() {},
         async onOk() {
            await FundStore.approveFund(value?.id, { reject: false })
            getListData(0, {
               text: trim(textSearch),
               from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
               to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
            })
         }
      })
   }

   const handleConfirm = (value: any) => {
      // console.log('handleConfirm: ', value)

      setTopup(value)
      if (value?.type === typeFund.WITHDRAW) {
         setIsTopupModalOpen(true)
      } else {
         confirm({
            title: t(GLOBAL_CLIENT.banchapnhanphieuyeucaunay),
            icon: <ExclamationCircleOutlined />,
            // content: t(GLOBAL_CLIENT.logoutDescription),
            okText: t(GLOBAL_CLIENT.apply),
            cancelText: t(GLOBAL_CLIENT.huy),
            onCancel() {},
            async onOk() {
               await FundStore.approveFund(value?.id, { reject: false })
               getListData(0, {
                  text: trim(textSearch),
                  from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
                  to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
               })
            }
         })
      }
   }

   const handleReject = (value: any) => {
      confirm({
         title: t(GLOBAL_CLIENT.bantuchoiphieuyeucaunay),
         icon: <ExclamationCircleOutlined />,
         okText: t(GLOBAL_CLIENT.tuchoi),
         cancelText: t(GLOBAL_CLIENT.huy),
         onCancel() {},
         async onOk() {
            await FundStore.approveFund(value?.id, { reject: true })
            getListData(0, {
               text: trim(textSearch),
               from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
               to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
            })
         }
      })
   }

   const handleEdit = () => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
      setVisibleDetail(false)
      clearDetailInfo()
   }
   const onRefreshList = () => {
      getListData(0, {
         text: trim(textSearch),
         from: moment(startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         to: moment(endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString()
      })
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      clearDetailInfo()
   }

   const handleCancel = () => {
      setVisibleCreate(false)
   }

   const handleCloseTopup = () => {
      setIsTopupModalOpen(false)
   }

   const showDetailAccount = (item: any) => () => {
      setItemAccount(item)
      setTimeout(() => {
         setVisibleDetailAccount(true)
      }, 300)
   }
   const columns = [
      {
         title: t(GLOBAL_CLIENT.receiptId),
         dataIndex: 'code',
         key: 'code',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.type),
         dataIndex: 'type',
         key: 'type',
         width: 80,
         render: (value: any) => {
            return <span className="txtMax2Line">{getTypeThuChi(value)}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.createAt),
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         width: 80,
         render: (value: string) => {
            return <span className="txtMax2Line">{moment(value).format(DATE_TIME_FORMAT_CLIENT)}</span>
         },
         sorter: {
            compare: (a: any, b: any) =>
               parseFloat(moment(a.createdAt).format('YYYYMMDDHHmmss')) -
               parseFloat(moment(b.createdAt).format('YYYYMMDDHHmmss')),
            multiple: 3
         },
         defaultSortOrder: 'descend' as 'descend'
      },
      // {
      //    title: t(GLOBAL_CLIENT.nhomNguoiNhanNop),
      //    dataIndex: 'withdrawer',
      //    key: 'withdrawer',
      //    width: 80,
      //    render: (value: any) => {
      //       return <span className="txtMax2Line">{'NMa Nam'}</span>
      //    }
      // },
      {
         title: t(GLOBAL_CLIENT.nguoiNhanNop),
         dataIndex: 'account',
         key: 'account',
         width: 100,
         render: (value: any) => {
            return (
               <span className="gx-link txtMax2Line" onClick={showDetailAccount(value)}>
                  {value?.name ? value?.name + `[${value?.role}]` : ''}
               </span>
            )
         }
      },
      {
         title: t(GLOBAL_CLIENT.moneyIn),
         dataIndex: 'price',
         key: 'price',
         width: 100,
         render: (value: any, record: any) => {
            return (
               <div>
                  {record?.type === typeFund.REFUND ? (
                     value > 0 ? (
                        <>
                           <span className="txtMax2Line">{formatCurrency(record?.priceVND, true)} đ</span>
                        </>
                     ) : (
                        ''
                     )
                  ) : record?.type == typeFund.COLLECT || record?.type == typeFund.RECHARGE ? (
                     <>
                        <span className="txtMax2Line">{formatCurrency(record?.priceVND, true)} đ</span>
                     </>
                  ) : (
                     ''
                  )}
               </div>
            )
         }
      },
      {
         title: t(GLOBAL_CLIENT.moneyOut),
         dataIndex: 'price',
         key: 'price',
         width: 100,
         render: (value: any, record: any) => {
            return (
               <div>
                  {record?.type === typeFund.REFUND ? (
                     value < 0 ? (
                        <>
                           <span className="txtMax2Line">{formatCurrency(record?.priceVND, true)} đ</span>
                        </>
                     ) : (
                        ''
                     )
                  ) : record?.type == typeFund.SPEND || record?.type == typeFund.WITHDRAW ? (
                     <>
                        <span className="txtMax2Line">{formatCurrency(record?.priceVND, true)} đ</span>
                     </>
                  ) : (
                     ''
                  )}
               </div>
            )
         }
      },
      {
         title: 'Quy đổi USD',
         dataIndex: 'price',
         key: 'price',
         width: 100,
         render: (value: any, record: any) => {
            return <span className="txtMax2Line">${formatCurrency(value)}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.content),
         dataIndex: 'description',
         key: 'description',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Chứng từ',
         dataIndex: 'chungtu',
         key: 'chungtu',
         width: 120,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: t(GLOBAL_CLIENT.status),
         dataIndex: 'status',
         key: 'status',
         width: 100,
         render: (value: any) => {
            return <span className="txtMax2Line">{getStatusThuChi(value)}</span>
         }
      },

      {
         title: 'action',
         key: 'action',
         dataIndex: 'updatedAt',
         width: 120,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (text: string, record: any) => {
            return record?.status == statusFund.NEW ? (
               <span className="txtMax2Line">
                  <span onClick={() => handleConfirm(record)} className="gx-link ">
                     {t(GLOBAL_CLIENT.xacnhan)}
                  </span>
                  <Divider type="vertical" />
                  <span onClick={() => handleReject(record)} className="gx-link" style={{ color: 'red' }}>
                     {t(GLOBAL_CLIENT.tuchoi)}
                  </span>
               </span>
            ) : (
               <span onClick={() => showDetail(record)} className="gx-link">
                  {t(GLOBAL_CLIENT.detail)}
               </span>
            )
         }
      }
   ]

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   const handleRangePickerChange = (date: any) => {
      // set_dateRangerPicker(date);
      //  console.log('datee: ', date)
      setStartTime(date[0])
      setEndTime(date[1])
   }

   const renderHeader = () => {
      return (
         <Row
            className="row-container-head"
            style={{ background: '#fff', padding: 12, borderRadius: 6, marginLeft: 0, marginRight: 0 }}
         >
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Số dư đầu kỳ</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#6998B9', fontWeight: 'bolder' }}>
                     {formatCurrency(before, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>+</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tổng thu</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#89AD88', fontWeight: 'bolder' }}>
                     {formatCurrency(collect, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>-</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tổng chi</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#B87986', fontWeight: 'bolder' }}>
                     {formatCurrency(spend, true)}
                  </span>
               </Col>
            </Col>
            <Col span={1} style={{ alignSelf: 'center' }}>
               {/* <span style={{ fontWeight: 'bolder' }}>=</span> */}
            </Col>
            <Col span={4}>
               <Col>
                  <span style={{ fontWeight: 'bold' }}>Tồn cuối kỳ</span>
               </Col>
               <Col>
                  <span style={{ fontSize: 18, color: '#3F74A1', fontWeight: 'bolder' }}>
                     {formatCurrency(after, true)}
                  </span>
               </Col>
            </Col>
         </Row>
      )
   }

   return (
      <>
         <Loading isLoading={isLoading} />
         <Row>
            <Col span={24}>
               {/* <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.txtSearch)}
                        allowClear
                     />
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           {renderFilter()}
                           <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />
                              {t(GLOBAL_CLIENT.create)}
                           </Button>
                        </Space>
                     </div>
                  </Col>
               </Row> */}
               <Row className="row-container-head">
                  <Col xs={24} md={12}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        placeholder={t(GLOBAL_CLIENT.txtSearch)}
                        allowClear
                     />
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           <RangePicker
                              allowClear={false}
                              defaultValue={[startTime, endTime]}
                              format={DATE_FORMAT_CLIENT}
                              locale={locale}
                              onChange={handleRangePickerChange}
                           />
                           <Button type="primary" className="btn-add" onClick={showCreate}>
                              <i className="icon icon-add icon-in-button" />

                              {t(GLOBAL_CLIENT.create)}
                           </Button>
                        </Space>
                     </div>
                  </Col>
               </Row>

               {renderHeader()}
               <Space className="total-items">
                  {t(GLOBAL_CLIENT.totalItems)}: {count}
               </Space>

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 280 }}
                  columns={columns}
                  dataSource={rows}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {renderPagination()}
            </Col>
         </Row>
         {visibleCreate && <Create visible={visibleCreate} onOk={handleCreate} onCancel={handleCancel} />}
         {item && visibleDetail && (
            <Detail visible={visibleDetail} item={item} onOk={handleEdit} onCancel={hideDetail} />
         )}
         {/* {itemDesigner && visibleUserDetailDesigner && (
            <DetailDesigner
               visible={visibleUserDetailDesigner}
               item={itemDesigner}
               onOk={handleEditDesigner}
               onCancel={hideDetailDesigner}
               onlyView
            />
         )}
         {itemCreator && visibleUserDetailCreator && (
            <DetailCreator
               visible={visibleUserDetailCreator}
               item={itemCreator}
               onOk={handleEditCreator}
               onCancel={hideDetailCreator}
               onlyView
            />
         )} */}
         {isTopupModalOpen && (
            <TopupAdminModal
               isTopupModalOpen={isTopupModalOpen}
               topup={topup}
               handleCancel={handleCloseTopup}
               onRefreshList={onRefreshList}
            />
         )}
      </>
   )
})

export default FundManagement
