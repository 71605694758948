import { observable, action } from 'mobx'
import { responseDefault } from './store.constants'
import { isEmpty } from 'lodash'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'

export class SkuStore {
   @observable listProducts: any = { ...responseDefault }
   @observable activeTab = '1'
   @action
   async fetchListProducts(id: string) {
      const result = await Request2.getWithToken(`${api.sku.getListProducts}/${id}`, {})
      if (!isEmpty(result)) {
         this.listProducts = result
      }
   }
   @action
   resetListProducts() {
      this.listProducts = {}
   }
   @action
   setListProducts(value: any) {
      this.resetListProducts()
      this.listProducts = { rows: [...value] }
   }
   @action
   async updateProducts(id: string, params: any = {}) {
      const result = await Request2.postWithToken(params, `${api.sku.getListProducts}/${id}`)
      console.log('------updatePrice', JSON.stringify(result))
      // if (!isEmpty(result)) {
      //   this.designerConfigList = { ...result };
      // }
      return result
   }

   @action
   async setActiveTab(tab: string) {
      this.activeTab = tab
   }
}
