import { useEffect, useState, useRef, forwardRef } from 'react'
import { Modal, Form, Button, Col, Row, Typography, Space, Tag } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import styled from 'styled-components'
import ImageSlick from 'src/common/components/ImageSlider'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

export const ModalDetailWrapper = styled.div`
   .title-product {
      font-size: 18px;
      font-weight: bold;
   }
   .content-product {
      font-size: 18px;
      font-weight: bold;
   }
   .image-wrapper {
      padding: 0 20px;
   }
   .product-description-wrapper {
      .btn-ellipsis {
         width: 50%;
      }
   }

   .quantity-product {
      margin-top: 200px;
      .quantity {
         width: 30px;
         text-align: center;
      }
      .btn-quantity {
         line-height: 1.3;
      }
   }

   @media only screen and (max-width: 1200px) {
      .image-wrapper {
         margin-bottom: 20px;
      }
      .product-description-wrapper {
         margin-top: 20px;
      }
      .quantity-product {
         margin-top: 0;
      }
   }

   @media screen and (max-width: 575px) {
      .title-product,
      .content-product {
         font-size: 14px;
      }
      .product-description-wrapper {
         margin-top: 20px;
         .title-description {
            font-size: 16px;
         }
         .product-description,
         .btn-ellipsis {
            font-size: 12px;
         }
      }
      .quantity-product {
         margin-top: 0;
         .quantity {
            margin-bottom: 10px;
         }
         .btn-quantity {
            line-height: 1.1;
         }
         .ant-btn-circle {
            min-width: 26px;
         }
         .ant-btn-icon-only {
            width: 26px;
            height: 26px;
            & > * {
               font-size: 14px;
            }
         }
      }
   }
`
const CreatorDetailScreen = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, onlyView } = props
   const AuthStore = useStore('AuthStore')
   const role: any = AuthStore.data_role
   const [form] = Form.useForm()
   const { Title, Paragraph } = Typography
   const [ellipsis, setEllipsis] = useState(true)

   useEffect(() => {}, [])

   const renderContent = () => {
      return (
         <ModalDetailWrapper>
            <Form form={form} name="DetailProduct">
               <Row>
                  <Col xs={24} xl={10}>
                     <div className="image-wrapper">
                        <ImageSlick images={item?.images} />
                     </div>
                     <div className="product-description-wrapper">
                        <Title className="title-description" level={4}>
                           Mô tả sản phẩm
                        </Title>
                        <Paragraph
                           className="product-description"
                           ellipsis={ellipsis ? { rows: 2, expandable: false } : false}
                        >
                           {item?.description}
                        </Paragraph>
                        <div className="gx-d-flex gx-justify-content-center">
                           <Button
                              className="btn-ellipsis"
                              onClick={() => {
                                 setEllipsis(!ellipsis)
                              }}
                           >
                              {ellipsis ? 'Show More' : 'Show Less'}
                           </Button>
                        </div>
                     </div>
                  </Col>
                  <Col xs={24} xl={14}>
                     <Space size={'large'} direction="vertical" style={{ width: '100%', paddingBottom: '20px' }}>
                        <Row>
                           <Col className="col-title" span={8}>
                              <span className="title-product">Tên sản phẩm</span>
                           </Col>
                           <Col className="col-content" span={16}>
                              <span className="content-product">{item?.nAme}</span>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="col-title" span={8}>
                              <span className="title-product">SKU</span>
                           </Col>
                           <Col className="col-content" span={16}>
                              <span className="content-product">{item?.id}</span>
                           </Col>
                        </Row>
                        <Row>
                           <Col className="col-title" span={8}>
                              <span className="title-product">Tag</span>
                           </Col>
                           <Col className="col-content" span={16}>
                              {item?.listTags &&
                                 item?.listTags?.map((it: any) => {
                                    return (
                                       <Tag color="gold" style={{ textTransform: 'capitalize' }}>
                                          {it.name}
                                       </Tag>
                                    )
                                 })}
                           </Col>
                        </Row>
                     </Space>
                     <Row className="quantity-product">
                        {/* <Col className="col-title" xs={8} xl={5}>
                           <span className="title-product">Số lượng</span>
                        </Col>
                        <Col className="col-content" xs={16} xl={19}>
                           <div className="gx-d-flex gx-align-items-center ">
                              <Button
                                 className="btn-quantity"
                                 size={'middle'}
                                 shape="circle"
                                 icon={<MinusOutlined />}
                              ></Button>
                              <div className="quantity">
                                 <span>{1} </span>
                              </div>
                              <Button
                                 className="btn-quantity"
                                 shape="circle"
                                 size={'middle'}
                                 icon={<PlusOutlined />}
                                 style={{ marginLeft: '15px' }}
                              ></Button>
                           </div>
                        </Col> */}
                     </Row>
                  </Col>
               </Row>
            </Form>
         </ModalDetailWrapper>
      )
   }

   return !onlyView ? (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1200px' }}
         visible={visible}
         centered
         title={'Chi tiết sản phẩm'}
         okText={t(GLOBAL_CLIENT.save)}
         cancelText={t(GLOBAL_CLIENT.close)}
         onCancel={onCancel}
         onOk={form.submit}
      >
         {renderContent()}
      </Modal>
   ) : (
      <Modal
         width={'90%'}
         style={{ maxWidth: '1200px' }}
         visible={visible}
         centered
         title={'Chi tiết sản phẩm'}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default CreatorDetailScreen
